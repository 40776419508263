/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import SignupFormHelper from "../../components/SignupFormHelper";
import LinkedInTncModal from "../../components/Signup/LinkedInTncModal";
import LinkedInLoginButton from "../../components/LinkedInLoginButton";
import ButtonMVP from "../../components/ButtonMVP";
import lynkLogo from "../../images/signup/lynk-neutral-logo.svg";
import ContentColumn from "../../components/ContentColumn";
import LinkedInBtn from "../../components/Signup/LinkedInBtn";
import TextFieldWithLabel from "../../components/TextFieldWithLabel";
import PasswordFieldWithLabel from "../../components/PasswordFieldWithLabel";
import CheckBoxWithLabel from "../../components/CheckBoxWithLabel";
import BasicTncShortLinks from "../../components/BasicTncShortLinks";
import { loginWithLinkedInSubmit } from "../../utils/linkedin";
import styles from "./styles.module.scss";
import { checkNoFieldErrors, getErrorClass } from "./../../utils/signupHelper";
import { EXISTING_EXPERT_MAGICLINK_RESENT } from "../../components/SignupFormHelper/messages";
import MiniLoader from "../../components/MiniLoader";
import { signUpByPassword, getCircleByCode } from "../../api/lynkClient";
import { get, isEmpty } from "lodash";
// import ReCaptcha from "../../components/ReCaptcha";

export default function SelfSignup(props) {
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [circle, setCircle] = useState({});
  const navigate = useNavigate();
  const { search } = useLocation();
  const { circleCode = "" } = useParams();
  useEffect(() => {
    hideLoading();
  }, []);

  useEffect(() => {
    if (!isEmpty(circleCode)) {
      getCircleByCode(circleCode)
        .then((response) => {
          setLoading(true);
          const { data = {} } = response;
          if (data.success && data.circle && data.circle.isSelfSignupAllowed) {
            props.setCircleFaviconUrl(data.circle.faviconURL);
            setCircle(data.circle);
          } else navigate("/signup");
        })
        .catch((err) => console.error(err))
        .finally(() => {
          setLoading(false);
        });
    } else setLoading(false);
  }, [circleCode, navigate, props]);

  const handleCreateUserAccount = async (data) => {
    const profile = {
      registrationEmail: data.email,
      firstName: data.name,
      lastName: data.lastname,
      contactEmail: [data.email],
      password: data.password,
      appLinkedInId: data.linkedinId,
      captcha: data.captcha,
      source: "PLATFORM",
      saveShortTnC: data.saveShortTnC,
      siteId: "",
    };
    if (!isEmpty(circleCode) && get(circle, "isSelfSignupAllowed")) {
      profile.circleCode = circleCode;
    }
    const response = await signUpByPassword(profile);
    if (response && response.data && get(response, "data.success", false)) {
      props.setIsAuthenticated(true);
      hideLoading();
      navigate(`/redirect${search}`);
    } else {
      setErrorMessage("something went wrong!");
      hideLoading();
    }
  };

  const onCreateAccountSubmit = (userEmail, userPassword, captcha) => {
    const { user } = props;
    const fieldsData = {
      email: userEmail,
      password: userPassword,
      saveShortTnC: true,
      tncReferenceId: get(user, "tncReferenceId", null),
      captcha,
    };
    handleCreateUserAccount(fieldsData);
    showLoading();
  };

  const setupWithLinkedIn = async (linkedInProps) => {
    showLoading();
    if (!isEmpty(circleCode)) {
      linkedInProps.circleCode = circleCode;
    }
    const result = await loginWithLinkedInSubmit({
      ...linkedInProps,
    });
    hideLoading();
    if (result.errorMessage) {
      setErrorMessage(result.errorMessage);
    }
    props.onLinkedInLogin(result);
  };

  const handleError = (error) => {
    setErrorMessage(error);
  };

  const hideLoading = () => setLoading(false);

  const showLoading = () => setLoading(true);

  const { linkedinLoading } = props.linkedin || "";
  const isLoading = errorMessage ? false : loading || linkedinLoading;
  const helperText =
    "Password should be unique with at least 12 characters, and containing at least one uppercase letter, one special character, and one number.";
  if (isLoading)
    return (
      <div className={styles.pageWrapper}>
        <MiniLoader />
      </div>
    );
  return (
    <div className={styles.pageWrapper}>
      {
        <div className={styles["form-selfSignUp"]} data-testid="signup-form">
          <SignupFormHelper
            onCreateAccountSubmit={onCreateAccountSubmit}
            setIsAuthenticated={props.setIsAuthenticated}
            linkedin={props.linkedin}
            loading={loading}
            siteKey={
              props.siteKey && props.siteKey.checkbox
                ? props.siteKey.checkbox
                : null
            }
          >
            {(formState, formProps) => (
              <React.Fragment>
                <ContentColumn>
                  <div
                    className={
                      !formState.showFullSignup
                        ? styles.partialSignUpForm
                        : styles.completeSignUpForm
                    }
                  >
                    <div className={styles.headingWrap}>
                      <h1>
                        Sign up to {get(circle, "circleName", "Member Portal")}
                      </h1>
                      {formState.showFullSignup && (
                        <React.Fragment>
                          <LinkedInBtn
                            disabled={
                              !!formState.magicLinkForLinkedInEmailResent
                            }
                            id="linkedInSignup"
                            text="Sign up via LinkedIn"
                            onClick={formProps.openLinkedInTncModal}
                          />
                        </React.Fragment>
                      )}
                      {formState.magicLinkForLinkedInEmailResent && (
                        <div className={styles.magicLinkSentMsg}>
                          {EXISTING_EXPERT_MAGICLINK_RESENT}
                        </div>
                      )}
                      {errorMessage && (
                        <div className={styles.errorMessage}>
                          <span
                            className={`${styles.errorIcon} fa fa-exclamation-circle`}
                          ></span>
                          <span className={styles.errorText}>
                            {errorMessage}
                          </span>
                        </div>
                      )}
                      {formState.errorMessage && (
                        <div className={styles.errorMessage}>
                          <span
                            className={`${styles.errorIcon} fa fa-exclamation-circle`}
                          ></span>
                          <span className={styles.errorText}>
                            {formState.errorMessage}
                          </span>
                        </div>
                      )}
                      {formState.showFullSignup && (
                        <div className={styles.sep}>
                          <span>or</span>
                        </div>
                      )}
                      <div className={styles.formBody}>
                        <div className={styles.fieldWrap}>
                          <TextFieldWithLabel
                            onKeyUp={formProps.onKeyPress}
                            disabled={formState.showFullSignup}
                            label="Email"
                            value={formState.userEmail}
                            name="userEmail"
                            type="text"
                            onBlur={formProps.onBlur}
                            onChange={formProps.handleOnChange}
                            required
                          />
                          {formProps.hasError("userEmail") && (
                            <div className={styles.errorMessage}>
                              {formState.formErrors.userEmail}
                            </div>
                          )}
                          {formState.magicLinkResent && (
                            <div className={styles.magicLinkSentMsg}>
                              {EXISTING_EXPERT_MAGICLINK_RESENT}
                            </div>
                          )}
                        </div>
                        {!formState.showFullSignup && (
                          <div className={styles.captchaWrapper}>
                            <div className={styles.btnWrap}>
                              <ButtonMVP
                                disabled={
                                  (formState.formErrors &&
                                    !!formState.formErrors.userEmail) ||
                                  formState.magicLinkResent
                                }
                                id="verifyEmail"
                                onClick={formProps.continueButtonClickHandler}
                              >
                                Create an account
                              </ButtonMVP>
                            </div>
                            <span className={styles.loginLink}>
                              Already have a member account?{" "}
                              <Link
                                to={`${
                                  isEmpty(circleCode)
                                    ? "/"
                                    : `/circle/${circleCode}/`
                                }login`}
                              >
                                Log in
                              </Link>
                            </span>
                          </div>
                        )}
                        {formState.showFullSignup && (
                          <React.Fragment>
                            <div className={styles.fieldWrap}>
                              <PasswordFieldWithLabel
                                helperText={helperText}
                                label="Password"
                                className={getErrorClass(
                                  "userPassword",
                                  formProps.hasError
                                )}
                                value={formState.userPassword}
                                name="userPassword"
                                type="password"
                                required
                                onBlur={formProps.onBlur}
                                onChange={formProps.handleOnChange}
                              />
                              {formProps.hasError("userPassword") && (
                                <div className={styles.errorMessage}>
                                  {formState.formErrors.userPassword}
                                </div>
                              )}
                            </div>
                            <div className={styles.fieldWrap}>
                              <PasswordFieldWithLabel
                                label="Confirm Password"
                                className={getErrorClass(
                                  "userConfirmPassword",
                                  formProps.hasError
                                )}
                                value={formState.userConfirmPassword}
                                name="userConfirmPassword"
                                type="password"
                                required
                                onBlur={formProps.onBlur}
                                onChange={formProps.handleOnChange}
                              />
                              {formProps.hasError("userConfirmPassword") && (
                                <div className={styles.errorMessage}>
                                  {formState.formErrors.userConfirmPassword}
                                </div>
                              )}
                            </div>
                            {/* <div className={styles.captchaWrapper}>
                              <ReCaptcha
                                key="checkboxCaptcha"
                                sitekey={formProps.siteKey}
                                onChange={formProps.captchaOnChange}
                              />
                            </div> */}
                            <div className={styles.fieldWrap}>
                              <CheckBoxWithLabel
                                checked={formState.userBasicTnc}
                                name="userBasicTnc"
                                required
                                onChange={formProps.handleOnChange}
                              >
                                <BasicTncShortLinks />
                              </CheckBoxWithLabel>
                            </div>
                            <div className={styles.btnWrap}>
                              <ButtonMVP
                                disabled={
                                  !checkNoFieldErrors(formState.formErrors)
                                  // !formState.captcha
                                }
                                id="creatAccount"
                                onClick={formProps.onCreateAccount}
                              >
                                Create an account
                              </ButtonMVP>
                            </div>
                            <span className={styles.loginLink}>
                              Already have a member account?{" "}
                              <Link
                                to={`${
                                  isEmpty(circleCode)
                                    ? "/"
                                    : `/circle/${circleCode}/`
                                }login`}
                              >
                                Log in
                              </Link>{" "}
                            </span>
                          </React.Fragment>
                        )}
                      </div>
                    </div>
                  </div>
                </ContentColumn>
                <LinkedInTncModal
                  isOpen={formState.linkedInTncModal}
                  title="Sign up with LinkedIn"
                  closeModal={formProps.closeLinkedInTncModal}
                >
                  <p>
                    You will be prompted to sign in to your LinkedIn account and
                    and authorise Lynk to sync your basic information from to
                    your profile
                  </p>
                  <CheckBoxWithLabel
                    checked={formState.userBasicTncLinkedIn}
                    name="userBasicTncLinkedIn"
                    required
                    onChange={formProps.handleOnChange}
                  >
                    <BasicTncShortLinks />
                  </CheckBoxWithLabel>
                  <div className={styles.btnsWrap}>
                    <LinkedInLoginButton
                      callback={(obj) =>
                        setupWithLinkedIn({
                          code: obj.code,
                          state: obj.state,
                        })
                      }
                      onError={handleError}
                    >
                      {(onClick) => (
                        <button
                          disabled={!formState.userBasicTncLinkedIn}
                          className={styles.continue}
                          id="continue"
                          onClick={onClick}
                        >
                          Continue
                        </button>
                      )}
                    </LinkedInLoginButton>
                    <button
                      className={styles.cancel}
                      id="cancel"
                      onClick={formProps.closeLinkedInTncModal}
                    >
                      Cancel
                    </button>
                  </div>
                </LinkedInTncModal>
                <img
                  className={
                    !formState.showFullSignup
                      ? styles.footerSignupLanding
                      : styles.footerSignupComplete
                  }
                  src={lynkLogo}
                  alt="signup"
                />
              </React.Fragment>
            )}
          </SignupFormHelper>
        </div>
      }
    </div>
  );
}
