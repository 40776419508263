import { useState, useEffect } from "react";
import styles from "./forgotPassword.module.scss";
import Loading from "../../components/Loading";
import HelmetWrapper from "../../components/HelmetWrapper";
import React from "react";
import ContentColumn from "../../components/ContentColumn";
import Footer from "../../components/Footer";
import TextFieldWithLabel from "../../components/TextFieldWithLabel";
import {
  addError,
  checkNoFieldErrors,
  getErrorClass,
} from "../../utils/signupHelper";
import ButtonMVP from "../../components/ButtonMVP";
import { Link } from "react-router-dom";
import { validateEmail } from "../../utils/emailValidation";
import { isEmpty } from "lodash";
import { forgetPassword } from "../../api/lynkClient";
// import ReCaptcha from "../../components/ReCaptcha";
// import PropTypes from "prop-types";

// ForgotPassword.propTypes = {
//   siteKey: PropTypes.object.isRequired,
// };
export default function ForgotPassword(props) {
  const [email, setFormData] = useState((props && props.email) || "");
  const [loading, setLoading] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [formErrors, setFormErrors] = useState([]);
  // const [captcha, setCaptcha] = useState(
  //   (props && props.siteKey.checkbox) || null
  // );

  const validateForm = () => {
    const fullError = formErrors || [];
    fullError.email = validateEmail(email) ? "" : "Email is invalid";
    return fullError;
  };

  const useFormValidation = () => {
    useEffect(() => {
      const fieldErrors = validateForm();
      setFormErrors({ ...fieldErrors });
    }, []);
  };

  useFormValidation();

  // const captchaOnChange = (captcha) => setCaptcha(captcha);

  const checkDisableSubmitBtn = (formErrors) => !checkNoFieldErrors(formErrors);

  const hasError = (field) =>
    !isEmpty(formErrors[field]) && formErrors[`${field}Dirty`];

  const onBlur = ({ target }) => {
    const error = validateEmail(target.value) ? undefined : "Email is invalid";
    const fieldErrors = addError(formErrors, target.name, error);
    setFormErrors(fieldErrors);
  };

  const handleOnChange = ({ target }) => {
    const { value } = target;
    setFormData(value);
    const fieldErrors = validateForm();
    setFormErrors(fieldErrors);
  };

  const onSubmit = () => {
    setLoading(true);
    forgetPassword({ registrationEmail: email, captcha: null })
      .then(() => {
        setShowSuccess(true);
      })
      .catch((error) => {
        setShowSuccess(false);
        setErrorMessage(error.message);
      })
      .finally(() => {
        setLoading(false);
        // setCaptcha(null);
      });
  };

  const displayForm = () => {
    return (
      <div className={styles.headingWrap}>
        <h1>Forgot your password</h1>
        <p>Enter the email address you use to sign in to reset your password</p>
        <div className={styles.formBody}>
          {errorMessage && (
            <div className={styles.errorMessage}>
              <span
                className={`${styles.errorIcon} fa fa-exclamation-triangle`}
              ></span>
              <span className={styles.errorText}>{errorMessage}</span>
            </div>
          )}
          <div className={styles.fieldWrap}>
            <TextFieldWithLabel
              className={getErrorClass("email", hasError)}
              label="Email"
              value={email}
              name="email"
              type="text"
              required
              onBlur={(event) => onBlur(event)}
              onChange={(event) => handleOnChange(event)}
            />
            {hasError("email") && (
              <div className={styles.errorMsg}>{formErrors.email}</div>
            )}
          </div>
          {/* <div
            style={{
              marginBottom: "32px",
              marginTop: "12px",
            }}
          >
            <ReCaptcha
              key="checkboxCaptcha"
              sitekey={props.siteKey.checkbox}
              onChange={captchaOnChange}
            />
          </div> */}
          <div>
            <div style={{ marginBottom: "15px" }}>
              <ButtonMVP
                disabled={checkDisableSubmitBtn(formErrors)}
                className="wide"
                id="forgot-password"
                onClick={onSubmit}
              >
                Submit
              </ButtonMVP>
            </div>
            <Link className={styles.backLink} to="/login">
              Back
            </Link>
          </div>
        </div>
      </div>
    );
  };

  const displaySuccess = () => (
    <div className={styles.headingWrap}>
      <h1>Email Sent</h1>
      <p className={styles.infoMsg}>
        If a <span translate="no">Lynk</span> account exists for{" "}
        <strong>{email}</strong>, an email will be sent to your inbox
        instructions on how to reset your password. You may need to check your
        your spam folder or unblock <strong>members@lynkpeople.com</strong>.
      </p>
      <p className={styles.infoMsg}>
        If you do not receive an email from us, then you may have signed up with
        another email address. If not, you can join our network by signing up{" "}
        <Link to="/signup">
          <strong>here</strong>
        </Link>
      </p>
    </div>
  );

  if (loading) return <Loading />;
  return (
    <div
      className={styles["forgotPwd-page"]}
      id="forgotPwd-form"
      data-testid="forgotPwd-form"
    >
      <React.Fragment>
        <HelmetWrapper title="Forgot Password">
          <ContentColumn showLogo={false}>
            {showSuccess ? displaySuccess() : displayForm()}
          </ContentColumn>
        </HelmetWrapper>
        <Footer />
      </React.Fragment>
    </div>
  );
}
