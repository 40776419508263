import React, { useState, useEffect } from "react";
import styles from "./magiclynk.module.scss";
import TextFieldWithLabel from "../TextFieldWithLabel";
import PasswordFieldWithLabel from "../PasswordFieldWithLabel";
import {
  checkNoformErrors,
  hasError,
  getErrorClass,
  validateFullForm,
  validateField,
} from "../../utils/fieldValidation";
import { VALIDATION_FIELDS } from "../../utils/constants";
import ButtonMVP from "../../components/ButtonMVP";
// import ReCaptcha from "../../components/ReCaptcha";
import LinkedInLoginButton from "../LinkedInLoginButton";
import LinkedInBtn from "../Signup/LinkedInBtn";
import { loginWithLinkedInSubmit } from "../../utils/linkedin";
import Loading from "../Loading";

export default function LoginMagicLynk(props) {
  const [formData, setFormData] = useState({
    email: props.data.email,
    password: "",
    captchaInvisible: "",
  });

  const [fieldErrors, setFieldErrors] = useState({});
  const [linkedinError, setlinkedinError] = useState("");
  const [loading, setLoading] = useState(false);
  // const [captchaInvisible, setCaptchaInvisible] = useState(null);
  // const invisibleRecaptchaRef = React.createRef();

  const useFormValidation = () => {
    useEffect(() => {
      function fetchData() {
        return validateFullForm(
          formData,
          fieldErrors,
          VALIDATION_FIELDS.LoginMagicLynk
        );
      }
      setFieldErrors(fetchData());
    }, []);
  };

  useFormValidation();

  const handleChange = (key, event) => {
    event.preventDefault();
    const value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    setFormData((state) => ({
      ...state,
      [key]: value,
    }));
    const formFields = {
      ...formData,
      [key]: value,
    };
    setFieldErrors(
      validateFullForm(
        formFields,
        fieldErrors,
        VALIDATION_FIELDS.LoginMagicLynk
      )
    );
  };

  const addError = (key, error) => {
    setFieldErrors((state) => ({
      ...state,
      [key]: error,
      [`${key}Dirty`]: true,
    }));
  };

  const onBlur = ({ target }) => {
    const { name: key, value } = target;
    let error;
    if (key === "email") {
      error = validateField(key, value);
    }
    if (!value && key === "password") {
      error = "Password required";
    }
    addError(key, error);
  };

  const loginWithEmail = () => {
    return props.onSubmit(formData);
  };

  // useEffect(() => {
  //   if (captchaInvisible) {
  //     loginWithEmail();
  //     setCaptchaInvisible(null);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [captchaInvisible]);

  // const triggerInvisibleCaptcha = () => {
  //   setCaptchaInvisible(null);
  //   invisibleRecaptchaRef.current.reset();
  //   invisibleRecaptchaRef.current.execute();
  // };
  // const invisibleCaptchaOnChange = (captchaInvisible) => {
  //   if (captchaInvisible) {
  //     setCaptchaInvisible(captchaInvisible);
  //     setFormData((state) => ({
  //       ...state,
  //       captchaInvisible: captchaInvisible,
  //     }));
  //   }
  // };
  const linkedInLoginBtnHandler = (callback) => {
    callback();
  };

  const loginWithLinkedin = async (linkedinInfo) => {
    setLoading(true);
    const result = await loginWithLinkedInSubmit({
      ...linkedinInfo,
    });
    setLoading(false);
    if (result.errorMessage) {
      setlinkedinError(result.errorMessage);
    }
    props.onLinkedInLogin(result);
  };
  if (loading) {
    return <Loading />;
  }

  const loginMsg =
    props && props.data && props.data.circleName
      ? `Login to ${props.data.circleName}`
      : "Login";
  return (
    <div className={styles["magic-lynk-form"]} data-testid="magic-lynk-form">
      <header>{loginMsg}</header>
      {props && props.data && props.data.circleName && (
        <LinkedInLoginButton
          onError={(errorMessage) => setlinkedinError(errorMessage)}
          callback={(obj) =>
            loginWithLinkedin({
              code: obj.code,
              state: obj.state,
            })
          }
        >
          {(onClick) => (
            <LinkedInBtn
              id="linkedInSignin"
              text="Log in with LinkedIn"
              onClick={() => {
                if (document.activeElement.id !== "linkedInSignin") {
                  return;
                }
                linkedInLoginBtnHandler(onClick);
              }}
            />
          )}
        </LinkedInLoginButton>
      )}
      {linkedinError && (
        <div className="errorMessage">
          <span className="errorIcon fa fa-exclamation-triangle"></span>
          <span className="errorText">{linkedinError}</span>
        </div>
      )}
      {props.errorMsg && (
        <div className={styles.errorMessage}>
          <span
            className={`${styles.errorIcon} fa fa-exclamation-triangle`}
          ></span>
          <span className={styles.errorText}>{props.errorMsg}</span>
        </div>
      )}
      <div className={styles.fieldWrap}>
        <TextFieldWithLabel
          disabled
          label="Email"
          data-testid="email"
          className={getErrorClass("Email", hasError)}
          name="email"
          type="text"
          value={formData.email}
          required
          onChange={(event) => handleChange("email", event)}
          onBlur={(event) => onBlur(event)}
        />
      </div>
      <div className={styles.fieldWrap}>
        <span className={styles.linkWrap} style={{ float: "right" }}>
          <a href="/forgotPassword">Forgot your password?</a>
        </span>
        <PasswordFieldWithLabel
          label="Password"
          className={getErrorClass("Password", hasError)}
          name="password"
          type="password"
          value={formData.password}
          required
          onChange={(event) => handleChange("password", event)}
          onBlur={(event) => onBlur(event)}
        />
        {hasError("password", fieldErrors) && (
          <div className={styles.errorMsg}>{fieldErrors.password}</div>
        )}
      </div>
      {/* {formData.email && (
        <div>
          <ReCaptcha
            ref={invisibleRecaptchaRef}
            sitekey={props.siteKey.invisible}
            onChange={invisibleCaptchaOnChange}
            size="invisible"
            badge="bottomright"
          />
        </div>
      )} */}
      <footer>
        <ButtonMVP
          className="wide"
          id="btnLogin"
          disabled={!checkNoformErrors(fieldErrors)}
          onClick={loginWithEmail}
        >
          Login
        </ButtonMVP>
      </footer>
      <img
        className="lynk-logo"
        src="https://enterprise.lynk.global/static/media/lynk-logo-neutral.050a3268.svg"
        alt="Lynk"
      />
    </div>
  );
}
